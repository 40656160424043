const delegate = require('../delegate');
const compose = require('../compose');

const SPLAT = '*';

module.exports = function delegateAll(selectors) {
  const keys = Object.keys(selectors)

  // XXX optimization: if there is only one handler and it applies to
  // all elements (the "*" CSS selector), then just return that
  // handler
  if (keys.length === 1 && keys[0] === SPLAT) {
    return selectors[SPLAT];
  }

  const delegates = keys.reduce(function(memo, selector) {
    memo.push(delegate(selector, selectors[selector]));
    return memo;
  }, []);
  return compose(delegates);
};
