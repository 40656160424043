'use strict';

function useNative() {
  var elem = document.createElement('div');
  elem.setAttribute('data-a-b', 'c');
  return Boolean(elem.dataset && elem.dataset.aB === 'c');
}

function nativeDataset(element) {
  return element.dataset;
}

module.exports = useNative() ? nativeDataset : function (element) {
  var map = {};
  var attributes = element.attributes;

  function getter() {
    return this.value;
  }

  function setter(name, value) {
    if (typeof value === 'undefined') {
      this.removeAttribute(name);
    } else {
      this.setAttribute(name, value);
    }
  }

  for (var i = 0, j = attributes.length; i < j; i++) {
    var attribute = attributes[i];

    if (attribute) {
      var name = attribute.name;

      if (name.indexOf('data-') === 0) {
        var prop = name.slice(5).replace(/-./g, function (u) {
          return u.charAt(1).toUpperCase();
        });
        var value = attribute.value;
        Object.defineProperty(map, prop, {
          enumerable: true,
          get: getter.bind({
            value: value || ''
          }),
          set: setter.bind(element, name)
        });
      }
    }
  }

  return map;
};

